import { Add } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getElements } from "../../../API/API";
import PageAdmin from "../../../components/layouts/PageAdmin";
import MUIDataTable from "mui-datatables";
import getMuiTheme from "../../../theme/TableStyle";
import TableOptions from "../../../components/columns/TableOptions";
import CuponsColumns from "../../../components/columns/CuponsColumns";
import Loading from "../../../components/layouts/Loading";
import CupoDialogAdd from "./CupoDialogAdd";
import CupoDialogMail from "./CupoDialogMail";

export default function CuponsAdmin() {
	const [cupons, setCupons] = useState();
	const [cupo, setCupo] = useState();
	const [open, setOpen] = useState(false);
	const [openMail, setOpenMail] = useState(false);
	const [update, setUpdate] = useState(0);
	const [loading, setLoading] = useState(true);
	const options = TableOptions();
	const columns = CuponsColumns(cupons, setOpen, setCupo, setOpenMail);

	useEffect(() => {
		const obtenir = async () => {
			const cupons = await getElements("cupons");
			setCupons(cupons.result);
			setLoading(false);
		};
		obtenir();
	}, [update]);
	console.log(cupons);

	return (
		<PageAdmin
			title="Cupons"
			button={
				<Tooltip title="Afegir cupó">
					<IconButton onClick={() => setOpen(true)}>
						<Add />
					</IconButton>
				</Tooltip>
			}
		>
			<Loading loading={loading}>
				<ThemeProvider theme={getMuiTheme()}>
					<MUIDataTable data={cupons?.filter((i) => Number(i.ilimitat) === 0)} columns={columns} options={options} />
				</ThemeProvider>
				<Typography variant="h2" mb={4}>
					Cupons il·limitats
				</Typography>
				<ThemeProvider theme={getMuiTheme()}>
					<MUIDataTable data={cupons?.filter((i) => Number(i.ilimitat) === 1)} columns={columns} options={options} />
				</ThemeProvider>
			</Loading>
			<CupoDialogAdd open={open} setUpdate={setUpdate} setOpen={setOpen} cupo={cupo} setCupo={setCupo} />
			<CupoDialogMail open={openMail} setUpdate={setUpdate} setOpen={setOpenMail} cupo={cupo} setCupo={setCupo} />
		</PageAdmin>
	);
}
